import { RenderCanvas } from "./RenderCanvas.jsx";
import { useCanvasContext } from "./Wrapper.jsx";
import { useImageCache } from "../../state/cache/useCache.js";
import { Canvas } from "../../components/InspectionCanvas/Canvas/index.js";
import { useMemo } from "react";
import { If } from "@nextml/lodestar-react";
import { isDefined } from "@nextml/lodestar";
import { Flex, Text } from "@chakra-ui/react";

export const ImageLayer = ({ imageId, quality = "width1920" }) => {
  const imageCache = useImageCache();
  const {
    state: { translation, scale, dimensions },
  } = useCanvasContext();

  const image = useMemo(
    () => ({
      render: Canvas.image({
        x: 0,
        y: 0,
        height: dimensions.original.height,
        width: dimensions.original.width,
      })(imageCache.poll(imageId, quality)),
    }),
    [imageCache, imageId, dimensions, quality],
  );

  return (
    <>
      <RenderCanvas
        renderables={[image]}
        translation={translation}
        scale={scale}
        dimensions={dimensions.ui}
      />
      <If
        test={
          (isDefined(imageCache.poll(imageId, quality).pending) ||
            isDefined(imageCache.poll(imageId, quality).inProgress)) &&
          !isDefined(imageCache.poll(imageId, quality)?.done) &&
          !isDefined(imageCache.poll(imageId, quality)?.error)
        }
      >
        <Flex
          width={"100%"}
          height={"100%"}
          position={"absolute"}
          top={0}
          left={0}
          zIndex={10}
          justifyContent={"center"}
          alignItems={"center"}
          backgroundColor={"#00000088"}
        >
          <Text
            color={"white"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Loading image...
          </Text>
        </Flex>
      </If>
    </>
  );
};
