import { Box } from "@chakra-ui/react";

import Select from "react-select";

import { useViewContext } from "../../ContextProvider.jsx";

import { groupBy } from "../../../utils/groupBy.js";
import { compose, map } from "@nextml/lodestar";

export const Filter = () => {
  const {
    dispatch,
    state: { filter },
  } = useViewContext();

  const onChange = compose(
    map((x) => x.value),
    groupBy,
    (selected) => dispatch({ setFilter: selected }),
  );

  return (
    <Box
      position={"relative"}
      justifyContent={"center"}
      zIndex={2}
      minWidth={"300px"}
      paddingRight={2}
    >
      <Select
        isMulti
        closeMenuOnSelect={false}
        options={filter.options}
        onChange={onChange}
        styles={styles}
        isRtl
      />
    </Box>
  );
};

const styles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "none",
    alignItems: "right",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: "black",
    color: "white",
    cursor: state.isDisabled ? "not-allowed" : "default",
  }),
  input: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    color: "white",
  }),
  placeholder: (styles) => ({ ...styles, color: "white" }),
  multiValue: (styles, _state) => ({
    ...styles,
    backgroundColor: "#256BEF",
    color: "white",
    borderRadius: "100px",
  }),

  multiValueLabel: (styles, _state) => ({
    ...styles,
    color: "white",
    paddingRight: "12px",
    paddingLeft: "0px",
  }),
  multiValueRemove: (styles, _state) => ({
    ...styles,
    color: "white",
    paddingLeft: "4px",
  }),
};
