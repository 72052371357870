import { Link as RouterLink } from "react-router-dom";
import { Flex, Image, Link, Spacer, VStack } from "@chakra-ui/react";
import {
  faPowerOff,
  faWrench,
  faTrainTrack,
} from "@fortawesome/sharp-regular-svg-icons";

import { NavLink } from "./NavLink";
import { MeasurementsView } from "../Measurements/index.jsx";

export const SIDEBAR_WIDTH = 110;

const LINK_SPACING = 6;
const LinkWrapper = ({ children }) => (
  <VStack
    spacing={LINK_SPACING}
    backgroundColor="#00000044"
    width={"full"}
    paddingTop={LINK_SPACING}
    paddingBottom={LINK_SPACING}
  >
    {children}
  </VStack>
);

export const Sidebar = () => (
  <Flex
    width={`${SIDEBAR_WIDTH}px`}
    direction={"column"}
    position={"fixed"}
    top={0}
    left={0}
    backgroundColor="#171717"
    height="100vh"
    alignItems={"center"}
  >
    <Link
      as={RouterLink}
      to="/"
      textDecoration="none"
      width={`${SIDEBAR_WIDTH}px`}
      height={`${SIDEBAR_WIDTH}px`}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Image
        src="/icon.white.png"
        height="47px"
      />
    </Link>

    <LinkWrapper>
      <NavLink
        name="Inspection"
        link={MeasurementsView.at()}
        faIcon={faTrainTrack}
      />
    </LinkWrapper>

    <Spacer />

    <LinkWrapper
      spacing={"1"}
      backgroundColor="#00000044"
      width={"full"}
      paddingTop="2"
      paddingBottom="2"
    >
      <NavLink
        name="Settings"
        link="/settings"
        faIcon={faWrench}
      />
      <NavLink
        name="Logout"
        link="/logout"
        faIcon={faPowerOff}
      />
    </LinkWrapper>
  </Flex>
);
