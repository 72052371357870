import { createContext, useContext, useReducer } from "react";

const ViewContext = createContext();

export const useViewContext = () => useContext(ViewContext);

export const ViewContextProvider = ({ children, reducer, initialState }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ViewContext.Provider value={{ state, dispatch }}>
      {children}
    </ViewContext.Provider>
  );
};
