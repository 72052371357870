import { InspectionCanvas } from "../../components/InspectionCanvas/index.jsx";
import { Box } from "@chakra-ui/react";

export const ImageViewer = ({ imageId }) => {
  return (
    <Box
      width={"100%"}
      borderRadius="6px"
      overflow={"hidden"}
      height={"100%"}
      boxShadow={"20px 20px 40px 0px #111111"}
    >
      <InspectionCanvas.Wrapper
        translation={(_canvasState) => ({ x: 0, y: 0 })}
      >
        <InspectionCanvas.ImageLayer imageId={imageId} />
        <InspectionCanvas.SegmentationLayer imageId={imageId} />
        <InspectionCanvas.MagnifyingGlass
          imageId={imageId}
          scale={4}
        />
        <InspectionCanvas.BoundingBoxLayer imageId={imageId} />
      </InspectionCanvas.Wrapper>
    </Box>
  );
};
