import { Td } from "@chakra-ui/react";
import { isDefined } from "@nextml/lodestar";

export const TableData = ({ children, ...props } = {}) => (
  <Td
    {...props}
    _first={
      {
        // paddingLeft: "7",
      }
    }
    _last={
      {
        // paddingRight: "7",
      }
    }
  >
    {isDefined(children) ? children : "-"}
  </Td>
);
