import { composeRequest } from "../ApiResult.js";

const ApplicationFormat = (constructApiUrl) => (apiResult) => {
  return apiResult.map((apiImage) => ({
    ...apiImage,
    quality: (quality) => ({
      url: constructApiUrl({
        endpoint: "/images/:id/data/quality/:quality",
        parameters: { id: apiImage.id, quality },
        query: {},
      }),
    }),
  }));
};

export const Images = (fetchEndpoint, constructApiUrl) => ({
  list: composeRequest(
    fetchEndpoint.get("/images"),
    ApplicationFormat(constructApiUrl),
  ),

  closest: fetchEndpoint.get("/images/closest"),
  read: fetchEndpoint.get("/images/:id/data/quality/:quality"),
  srcUrl: ({ id, quality }) =>
    constructApiUrl({
      endpoint: "/images/:id/data/quality/:quality",
      parameters: { id, quality },
      query: {},
    }),
});
