import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { LoadingView } from "../views/Loading.jsx";
import { Styles } from "../styles/Styles.jsx";

export const AuthenticationProvider = ({ children, config }) => {
  const keycloak = new Keycloak(config.auth);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={(event, error) => {
        /*
          onReady
          onInitError
          onAuthSuccess
          onAuthError
          onAuthRefreshSuccess
          onAuthRefreshError
          onTokenExpired
          onAuthLogout
        */
        console.log("ReactKeycloakProvider.event", event, error);
      }}
      LoadingComponent={
        <Styles>
          <LoadingView message="Loading the authentication..." />
        </Styles>
      }
      initOptions={{
        flow: "implicit",
        onLoad: "login-required",
      }}
    >
      {children}
    </ReactKeycloakProvider>
  );
};
