import { useContext, useReducer, useCallback, useMemo } from "react";
import { isDefined, composeAsync, sideEffect } from "@nextml/lodestar";
import { CanvasImageCacheContext } from "./contexts.js";
import { useApi } from "../../api/useApi.js";
import { Image } from "../../Image/index.js";
import { useApplicationState } from "@nextml/lodestar-react";
import { convertImageToOverlay } from "../../utils/convertImageToOverlay.js";

const SET = "SET";

const reducer = (cache, action) => {
  switch (action.type) {
    case SET: {
      return {
        ...cache,
        ...action.payload,
      };
    }

    default: {
      return cache;
    }
  }
};

export const CacheProvider = ({ children, context: CacheContext }) => {
  const [cache, dispatch] = useReducer(reducer, {});

  return (
    <CacheContext.Provider value={{ cache, dispatch }}>
      {children}
    </CacheContext.Provider>
  );
};

export const useCache = (context, fn) => {
  const { cache, dispatch } = useContext(context);

  const retrieve = useCallback(
    (...args) => {
      const key = JSON.stringify(args);
      composeAsync(
        sideEffect(() =>
          dispatch({ type: SET, payload: { [key]: { inProgress: true } } }),
        ),
        (args) => fn(...args),
        (data) => dispatch({ type: SET, payload: { [key]: { done: data } } }),
      )(args);

      return { pending: true };
    },
    [dispatch, fn],
  );

  const poll = useCallback(
    (...args) => {
      const key = JSON.stringify(args);
      if (isDefined(cache[key])) {
        return cache[key];
      } else {
        return retrieve(...args);
      }
    },
    [cache, retrieve],
  );

  const imageCache = useMemo(() => ({ poll }), [poll]);

  return imageCache;
};

export const useImageCache = () => {
  const { Images } = useApi();

  const fn = useCallback(
    (id, quality) =>
      Image.load(Images.srcUrl({ id, quality }), { crossOrigin: "Anonymous" }),
    [Images],
  );

  return useCache(CanvasImageCacheContext, fn);
};

export const useSegmentationCache = () => {
  const { config } = useApplicationState();
  const fn = useCallback(
    (imagePath) =>
      Image.load(`${config.api.endpoint}${imagePath}`, {
        crossOrigin: "Anonymous",
      }).then((image) => convertImageToOverlay(image, [255, 0, 0, 125])),
    [config.api.endpoint],
  );

  return useCache(CanvasImageCacheContext, fn);
};
