import {
  camelCaseToSnakeCase,
  compose,
  identity,
  map,
  reduce,
  typeOf,
} from "@nextml/lodestar";
import { pair, unPair } from "../../utils/pair.js";

const valueModifier = (key) => {
  switch (key) {
    case "fromLine": {
      return Math.floor;
    }
    case "toLine": {
      return Math.ceil;
    }

    default: {
      return identity;
    }
  }
};

const keyModifier = camelCaseToSnakeCase;

const expandArrayValues = (acc, parameterPair) => {
  const [key, value] = unPair(parameterPair);

  if (typeOf(value) === "array") {
    return [...acc, ...value.map(pair(key))];
  } else {
    return [...acc, parameterPair];
  }
};

const serialize = (urlQueryObject) => {
  const parameterNames = Object.keys(urlQueryObject);

  const urlSearchParams = new URLSearchParams();

  const urlParameterPairs = compose(
    map((parameterName) => ({
      [parameterName]: urlQueryObject[parameterName],
    })),
    reduce(expandArrayValues, []),
  )(parameterNames);

  urlParameterPairs.forEach((parameterPair) => {
    const [key, value] = unPair(parameterPair);
    urlSearchParams.append(keyModifier(key), valueModifier(key)(value));
  });

  return urlSearchParams.toString();
};

export const Query = {
  serialize,
};
