import { createContext, useCallback, useContext, useMemo } from "react";

import { noop, isDefined } from "@nextml/lodestar";
import { If } from "@nextml/lodestar-react";

import { EventCaptureCanvas } from "./EventCaptureCanvas.jsx";
import { ResizeObserverWrapper } from "../ResizeObserverWrapper.jsx";

import { useCanvasState } from "./state.js";
import { match } from "../../utils/match.js";

const CanvasContext = createContext();

export const useCanvasContext = () => useContext(CanvasContext);

const canvasCenterAtFocusLine = (canvas) => ({
  x: 0,
  y: -canvas.focusLine + canvas.dimensions.original.height / 2,
});

const adaptToInterfaceSize = (canvas) =>
  canvas.dimensions.ui.width / canvas.dimensions.original.width;

const defaultEventHandler = (_state) => (_event) => undefined;

export const Wrapper = ({
  children,
  initialState,
  translation = canvasCenterAtFocusLine,
  scale = adaptToInterfaceSize,
  externalChangeDependencies,
  onChange = defaultEventHandler,
  onMouseDown = defaultEventHandler,
  onMouseUp = defaultEventHandler,
  onScroll = defaultEventHandler,
  isDisabled = false,
} = {}) => {
  // To prevent update recursion
  const memoizedInitialState = useMemo(() => {
    if (isDefined(initialState)) {
      return initialState;
    } else {
      return {};
    }
  }, [initialState]);

  const onStateChange = useCallback(
    (stateChange) =>
      match({
        default: (_) => onChange(stateChange),
        init: (_) => onChange(stateChange),
        resize: (_) => onChange(stateChange),
        scroll: (_) => onScroll(stateChange),
        mousedown: (_) => onMouseDown(stateChange),
        mouseup: (_) => onMouseUp(stateChange),
        mouseleave: (_) => noop,
        external: (_) => onChange(stateChange),
        mousemove: noop,
        keydown: noop,
      })(stateChange.cause),
    [onChange, onMouseDown, onMouseUp, onScroll],
  );

  const { state, dispatch } = useCanvasState(
    memoizedInitialState,
    onStateChange,
    // NOTE: maybe move these later
    translation,
    scale,
    // NOTE: This is a bit of a hack...
    externalChangeDependencies,
  );

  return (
    <CanvasContext.Provider value={{ state, dispatch }}>
      <ResizeObserverWrapper
        onResize={dispatch}
        cursor={state.mouse.cursor}
        position="relative"
        width="100%"
        height="100%"
        overflow="hidden"
      >
        {children}

        <If test={!isDisabled}>
          <EventCaptureCanvas />
        </If>
      </ResizeObserverWrapper>
    </CanvasContext.Provider>
  );
};
