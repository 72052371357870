import { isDefined } from "@nextml/lodestar";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Authentication = ({ children }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDefined(keycloak)) {
      console.log("keycloak.authenticated", keycloak.authenticated);
    }
  }, [keycloak]);

  if (keycloak.authenticated) {
    return children;
  } else {
    navigate({ pathname: "/login" });
  }
};
