import { useCanvasContext } from "./Wrapper.jsx";
import { useMemo, useState, useEffect } from "react";
import { RenderCanvas } from "./RenderCanvas.jsx";
import { Canvas } from "./Canvas/index.js";
import { useSegmentationCache } from "../../state/cache/useCache.js";
import { useApi } from "../../api/useApi.js";

export const SegmentationLayer = ({ imageId }) => {
  const segmentationCache = useSegmentationCache();
  const { Predictions } = useApi();
  const [resolvedSegmentations, setResolvedSegmentations] = useState([]);
  const {
    state: { translation, scale, dimensions },
  } = useCanvasContext();

  useEffect(() => {
    const segmentations = Predictions.list({
      query: {
        type: "segmentation",
        imageId: imageId,
      },
    });
    segmentations.then(setResolvedSegmentations).catch((error) => {
      console.error("Failed to resolve segmentations:", error);
    });
  }, [Predictions, imageId]);

  const renderables = useMemo(() => {
    return resolvedSegmentations.map((segmentation) => {
      const rescale = {
        width: dimensions.original.width / segmentation.data.image_width,
        height: dimensions.original.height / segmentation.data.image_height,
      };
      return {
        render: Canvas.image({
          loadingOpacity: "00",
          x: segmentation.data.from_width * rescale.width,
          y: segmentation.data.from_line * rescale.height,
          width:
            (segmentation.data.to_width - segmentation.data.from_width) *
            rescale.width,
          height:
            (segmentation.data.to_line - segmentation.data.from_line) *
            rescale.height,
        })(segmentationCache.poll(segmentation.data.image_path)),
      };
    });
  }, [resolvedSegmentations, segmentationCache, dimensions.original]);

  return (
    <RenderCanvas
      renderables={renderables}
      dimensions={dimensions.ui}
      translation={translation}
      scale={scale}
    />
  );
};
