export const binaryToOverlay = (binaryImageData, color) => {
  // TODO: move this logic to backend (.../image-data?color=...)
  let rgbImageData = new ImageData(
    binaryImageData.width,
    binaryImageData.height,
  );
  for (let i = 0; i < binaryImageData.data.length; i += 4) {
    if (binaryImageData.data[i] === 255) {
      rgbImageData.data[i] = color[0];
      rgbImageData.data[i + 1] = color[1];
      rgbImageData.data[i + 2] = color[2];
      rgbImageData.data[i + 3] = color[3];
    } else {
      rgbImageData.data[i] = 0;
      rgbImageData.data[i + 1] = 0;
      rgbImageData.data[i + 2] = 0;
      rgbImageData.data[i + 3] = 0;
    }
  }

  return rgbImageData;
};
