import { Flex, Text } from "@chakra-ui/react";
import { Layout } from "./Layout";

export const Page404 = () => (
  <Layout
    backgroundColor="#000000"
    alignItems="center"
  >
    <Flex
      marginTop="32"
      alignItems={"center"}
      direction="column"
    >
      <Text
        fontSize={"9xl"}
        fontWeight="bold"
        color={"white"}
        borderBottom="2px solid"
        borderColor="orange.500"
        marginBottom={"10"}
      >
        404
      </Text>

      <Text
        fontSize={"3xl"}
        fontStyle="italic"
        color={"whiteAlpha.700"}
      >
        This page does not exist...
      </Text>
    </Flex>
  </Layout>
);
