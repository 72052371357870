import { Tr } from "@chakra-ui/react";
import { noop } from "@nextml/lodestar";

export const TableRow = ({ children, onDoubleClick = noop, ...props } = {}) => (
  <Tr
    onDoubleClick={onDoubleClick}
    _hover={{
      backgroundColor: "orange.50",
    }}
    {...props}
  >
    {children}
  </Tr>
);
