import { useRef, useState, useEffect } from "react";

import { isDefined } from "@nextml/lodestar";
import { far } from "@nextml/far-canvas";

// NOTE:
// { render: (context) => { /* render logic */ } }
const render = (context) => (renderObject) => {
  if (isDefined(renderObject?.render)) {
    return renderObject.render(context);
  } else {
    throw new TypeError(
      "A renderable object must be on the form { render: (context) => { /* render logic */ } }",
    );
  }
};

const useRender = (context, renderables) => {
  useEffect(() => {
    if (isDefined(context, renderables)) {
      context.save();
      context.clearCanvas();
      renderables.forEach(render(context));
      context.restore();
    }
  }, [context, renderables]);
};

export const RenderCanvas = ({
  renderables,
  style = {},
  translation,
  dimensions,
  scale,
} = {}) => {
  const canvasRef = useRef();
  const [context, setContext] = useState();

  useEffect(() => {
    if (isDefined(canvasRef.current)) {
      setContext(
        far(canvasRef.current, {
          x: translation.x,
          y: translation.y,
          scale,
        }).getContext("2d"),
      );
    }
  }, [canvasRef, translation, scale]);

  useRender(context, renderables);

  return (
    <canvas
      ref={canvasRef}
      width={dimensions.width}
      height={dimensions.height}
      style={{
        position: "absolute",
        ...style,
      }}
    />
  );
};
