import { curry } from "@nextml/lodestar";

export const pair = curry((key, value) => ({ [key]: value }));

export const unPair = (pair) => {
  const [key] = Object.keys(pair);
  const [value] = Object.values(pair);

  return [key, value];
};
