export const MEASUREMENT_HISTORY = "measurement history";
export const MEASUREMENT_OVERVIEW = "measurement overview";

// Detection types
export const SLEEPER = "sleeper";
export const RAIL = "rail";
export const FASTENER = "fastener";

// Add all features here
export const FEATURES = [
  SLEEPER,
  RAIL,
  FASTENER,
  MEASUREMENT_HISTORY,
  MEASUREMENT_OVERVIEW,
];
