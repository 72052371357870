import { composeAsync } from "@nextml/lodestar";

export const ApiResult = {
  ok: (data) => ({ ok: data }),
  error: (error) => ({ error }),
};

export const composeRequest =
  (...fns) =>
  (data) =>
    composeAsync(...fns, ApiResult.ok)(data).catch(ApiResult.error);
