import { Icon } from "@chakra-ui/react";
import {
  faChevronUp,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/sharp-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SortIcon = ({ isSelected, sortOrder }) => {
  if (isSelected) {
    switch (sortOrder) {
      case "descending": {
        return (
          <Icon
            as={FontAwesomeIcon}
            icon={faChevronUp}
          />
        );
      }

      case "ascending": {
        return (
          <Icon
            as={FontAwesomeIcon}
            icon={faChevronDown}
          />
        );
      }

      default: {
        return (
          <Icon
            as={FontAwesomeIcon}
            icon={faChevronRight}
          />
        );
      }
    }
  } else {
    return (
      <Icon
        as={FontAwesomeIcon}
        icon={faChevronRight}
      />
    );
  }
};
