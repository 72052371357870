import { Authentication } from "../authentication/Authentication.jsx";
import { SearchParamStateProvider } from "../state/searchParamState.js";
import { UrlStateProvider } from "../state/url/index.js";

export const View = ({ children, queryParameters = {}, ...props } = {}) => {
  return (
    <SearchParamStateProvider config={queryParameters}>
      <UrlStateProvider>{children}</UrlStateProvider>
    </SearchParamStateProvider>
  );
};

export const AuthenticatedView = ({ children, ...props }) => {
  return (
    <Authentication>
      <View {...props}>{children}</View>
    </Authentication>
  );
};
