import { Th, HStack, Text } from "@chakra-ui/react";

import { defer, noop } from "@nextml/lodestar";
import { If } from "@nextml/lodestar-react";

import { SortIcon } from "./SortIcon";

export const ColumnHeader = ({
  children,
  attribute,
  onClick,
  sortOrder,
  sortBy,
  disabled = false,
  ...props
} = {}) => {
  const isSelected = attribute === sortBy;

  return (
    <Th
      onClick={disabled ? noop : defer(onClick, attribute)}
      paddingTop="7"
      paddingBottom="5"
      {...props}
    >
      <HStack
        cursor={disabled ? "default" : "pointer"}
        color={isSelected ? "blue.500" : "inherit"}
        justifyContent={props.isNumeric ? "flex-end" : "inherit"}
        _hover={{
          color: disabled ? "inherit" : "blue.400",
        }}
      >
        <Text textAlign={"center"}>{children}</Text>
        <If test={!disabled}>
          <SortIcon
            isSelected={isSelected}
            sortOrder={sortOrder}
          />
        </If>
      </HStack>
    </Th>
  );
};

export const createColumnHeaderWith =
  ({ displayName, attribute }) =>
  ({ sortBy, sortOrder, onClick, ...props }) =>
    (
      <ColumnHeader
        attribute={attribute}
        onClick={onClick(attribute)}
        sortBy={sortBy}
        sortOrder={sortOrder}
        {...props}
      >
        {displayName}
      </ColumnHeader>
    );
