import { Flex } from "@chakra-ui/react";
import { Sidebar, SIDEBAR_WIDTH } from "./Sidebar";

export const Layout = ({ children, ...props }) => (
  <Flex
    height="100vh"
    width="100%"
  >
    <Sidebar />

    <Flex
      direction="column"
      flex={1}
      width={`calc(100% - ${SIDEBAR_WIDTH}px)`}
      marginLeft={`${SIDEBAR_WIDTH}px`}
      backgroundColor="gray.100"
      overflowY="auto"
      {...props}
    >
      {children}
    </Flex>
  </Flex>
);
