import { Box, Grid, GridItem, VStack } from "@chakra-ui/react";
import { useCarousel } from "./useCarousel.js";
import { If, Map } from "@nextml/lodestar-react";
import { isDefined } from "@nextml/lodestar";
import { useEffect } from "react";

export const Carousel = ({
  data,
  component: Component,
  onClick,
  onChange,
  id,
  size,
}) => {
  const carousel = useCarousel(data, id, size);

  useEffect(() => {
    if (isDefined(carousel.focusedImage)) {
      onChange(carousel.focusedImage);
    }
  }, [carousel, carousel.focusedImage, onChange]);

  return (
    <Box
      width="100%"
      height="100%"
      overflow="hidden"
      onKeyDown={carousel.onKeyDown}
      onWheel={carousel.onScroll}
      tabIndex="0"
      _focus={{
        outline: "none",
      }}
    >
      <Grid
        templateAreas={`"header"
                        "main"
                        "footer"`}
        gridTemplateRows={`1fr max-content 1fr`}
        gridTemplateColumns={"1fr"}
        gap="1"
        width="100%"
        height="100%"
        fontWeight="bold"
      >
        <GridItem
          area="header"
          position="relative"
          width="100%"
          margin="0 auto"
        >
          <VStack
            position="absolute"
            bottom="0px"
            width="100%"
            flex={1}
            overflowY="hidden"
            spacing={1}
          >
            <Map
              component={Component}
              data={carousel.top}
              keyFrom={(x) => x.id}
              onClick={onClick}
            />
          </VStack>
        </GridItem>

        <GridItem
          area="main"
          width={"100%"}
          marginTop={1}
          marginBottom={1}
        >
          <If test={isDefined(carousel.focusedImage)}>
            {
              <Component
                {...carousel.focusedImage}
                isSelected
              />
            }
          </If>
        </GridItem>

        <GridItem
          area="footer"
          position="relative"
          width="100%"
          margin="0 auto"
        >
          <VStack
            position="absolute"
            top="0px"
            height="100%"
            width="100%"
            flex={1}
            overflowY="hidden"
            spacing={1}
          >
            <Map
              component={Component}
              data={carousel.bottom}
              keyFrom={(x) => x.id}
              onClick={onClick}
              top="0px"
              height="100%"
            />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};
