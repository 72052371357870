import { ApplicationStateProvider } from "@nextml/lodestar-react";
import { actionSwitch } from "./state/actionSwitch.js";
import { initialState } from "./state/initialState.js";
import { Styles } from "./styles/Styles.jsx";
import { Views } from "./views";
import { FEATURES } from "./state/features.js";
import { CacheProvider } from "./state/cache/useCache.js";
import { CanvasImageCacheContext } from "./state/cache/contexts.js";

export const App = ({ config }) => (
  <Styles>
    <CacheProvider context={CanvasImageCacheContext}>
      <ApplicationStateProvider
        config={config}
        initialState={initialState}
        featureToggles={FEATURES}
        customActions={actionSwitch}
      >
        <Views />
      </ApplicationStateProvider>
    </CacheProvider>
  </Styles>
);
