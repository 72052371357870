import { useKeycloak } from "@react-keycloak/web";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";

import { currentUri } from "../utils/location";
import { redirectUriKey } from "../views/Login";
// import { useAuthorization } from "../authentication/useAuthorization.js";

const GET = "GET";
const POST = "POST";

const CONTENT_TYPE = "Content-Type";

const checkResponseStatus = (onClientError, onServerError) => (response) => {
  if (response.status >= 400) {
    return onClientError(response);
  }

  if (response.status >= 500) {
    return onServerError(response);
  }

  return response;
};

const resolveBody = (response) => {
  if (response.headers.get("content-type") === "application/json") {
    return response.json();
  } else {
    return response.blob();
  }
};

export const useAuthorizedFetcher = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const Authorization = {}; //useAuthorization();

  // TODO: make, popups and feedback, stuff like that...
  const onClientError = useCallback(
    (response) => {
      if (response.status === 422) {
        throw new Error("422 Unprocessable Entity");
      }

      if (response.status === 401) {
        return navigate({
          pathname: "/login",
          search: `?${redirectUriKey}=${encodeURIComponent(currentUri())}`,
        });
      }
    },
    [navigate],
  );

  // TODO: make, popups and feedback, stuff like that...
  const onServerError = useCallback((response) => {
    throw response;
  }, []);

  const get = useCallback(
    (url) =>
      fetch(url, {
        method: GET,
        headers: { ...Authorization.header },
      })
        .then(checkResponseStatus(onClientError, onServerError))
        .then(resolveBody),
    [Authorization.header, onClientError, onServerError],
  );

  const post = useCallback(
    (url) => (body) =>
      fetch(url, {
        method: POST,
        headers: {
          ...Authorization.header,
          [CONTENT_TYPE]: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then(checkResponseStatus(onClientError, onServerError))
        .then(resolveBody),
    [Authorization.header, onClientError, onServerError],
  );

  const authorizedFetcher = useMemo(
    () => ({
      get,
      post,
    }),
    [get, post],
  );

  if (keycloak?.token) {
    return authorizedFetcher;
  } else {
    return null;
  }
};
