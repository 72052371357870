import { updateIn } from "@nextml/lodestar";

export const REPLACE_BY_ID_IN = "REPLACE_BY_ID_IN";

export const actionSwitch = (state, action) => {
  const { type, key, payload } = action;

  switch (type) {
    case REPLACE_BY_ID_IN: {
      return updateIn(
        (xs) => xs.map((x) => (x.id === payload.id ? payload : x)),
        key,
        state,
      );
    }

    default: {
      return state;
    }
  }
};
