import { useEffect, useMemo, useReducer } from "react";

import { getMousePositionFromMouseEvent } from "./getMousePositionFromMouseEvent.js";

import { match } from "../../utils/match.js";
import { curry } from "@nextml/lodestar";

const SCROLL_SPEED_FACTOR = 0.5;

const calculateView = (focusLine, dimensions) => {
  return {
    from: Math.floor(focusLine - dimensions.original.height / 2),
    center: focusLine,
    to: Math.ceil(focusLine + dimensions.original.height / 2),
  };
};

export const getFocusLineFromEvent = (event) =>
  Math.round(event.deltaY * SCROLL_SPEED_FACTOR);

const defaultState = {
  cause: {
    default: {},
  },

  view: {
    from: 0,
    center: 0,
    to: 0,
  },

  mouse: {
    cursor: "auto",
    position: { x: 0, y: 0 },
    original: { x: 0, y: 0 },
    ui: { x: 0, y: 0 },
  },

  // This is to prevent division with 0
  // when the canvas is not yet rendered
  dimensions: {
    ui: { width: 1, height: 1 },
    original: { width: 1, height: 1 },
  },

  focusLine: 0,
  focusedPosition: { x: 0, y: 0 },
  magnifier: { display: false },

  isHovered: false,

  translation: { x: 0, y: 0 },
  scale: 1,
};

const toOriginalDimensions = curry((originalWidth, { width, height }) => ({
  width: originalWidth,
  height: Math.ceil(height * (originalWidth / width)),
}));

const reducer = (translationFunction, scaleFunction) => (state, cause) => {
  const translateAndScale = (state) => ({
    ...state,
    translation: translationFunction(state),
    scale: scaleFunction(state),
  });

  return match({
    default: (_) => state,

    init: (initialState) =>
      translateAndScale({
        ...state,
        ...initialState,
        view: calculateView(initialState.focusLine, state.dimensions),
        cause,
      }),

    resize: ({ width, height }) =>
      translateAndScale({
        ...state,
        dimensions: {
          ...state.dimensions,
          ui: { width, height },
          original: toOriginalDimensions(1920, { width, height }),
        },
        view: calculateView(state.focusLine, state.dimensions),
        cause,
      }),

    mousemove: (event) => ({
      ...state,
      isHovered: true,
      mouse: {
        ...state.mouse,
        position: getMousePositionFromMouseEvent(event),
        ui: {
          x: Math.round(
            state.dimensions.ui.width * getMousePositionFromMouseEvent(event).x,
          ),
          y: Math.round(
            state.dimensions.ui.height *
              getMousePositionFromMouseEvent(event).y,
          ),
        },
        original: {
          x: Math.round(
            state.dimensions.original.width *
              getMousePositionFromMouseEvent(event).x,
          ),
          y:
            state.view.from +
            Math.round(
              state.dimensions.original.height *
                getMousePositionFromMouseEvent(event).y,
            ),
        },
      },
      cause,
    }),

    mousedown: (event) => ({
      ...state,
      mouse: {
        ...state.mouse,
        position: getMousePositionFromMouseEvent(event),
        cursor: "none",
      },
      magnifier: {
        ...state.magnifier,
        display: true,
      },
      cause,
    }),

    mouseleave: (_) => ({
      ...state,
      isHovered: false,
      cause,
    }),

    mouseup: (event) => ({
      ...state,
      mouse: {
        ...state.mouse,
        position: getMousePositionFromMouseEvent(event),
        cursor: "auto",
      },
      magnifier: {
        ...state.magnifier,
        display: false,
      },
      clickedPosition: {
        measurementLine: {
          measurement: state.measurement,
          original:
            state.view.from +
            Math.round(
              state.dimensions.original.height *
                getMousePositionFromMouseEvent(event).y,
            ),
        },
      },
      cause,
    }),

    scroll: (event) => {
      const nextFocusLine = state.focusLine + getFocusLineFromEvent(event);

      // const focusedPosition = {
      //   x: 0,
      //   y: 0,
      // };

      return translateAndScale({
        ...state,
        focusLine: nextFocusLine,
        view: calculateView(nextFocusLine, state.dimensions),
        cause,
      });
    },

    keydown: (_event) => ({ ...state, cause }),
    external: (_event) => ({ ...state, cause }),
  })(cause);
};

export const useCanvasState = (
  initialState,
  onStateChange,
  translationFunction,
  scaleFunction,
  externalChangeDependencies,
) => {
  const [state, dispatch] = useReducer(
    reducer(translationFunction, scaleFunction),
    defaultState,
  );

  useEffect(() => {
    dispatch({ external: externalChangeDependencies });
  }, [externalChangeDependencies]);

  useEffect(() => {
    onStateChange(state);
  }, [onStateChange, state]);

  useEffect(() => {
    dispatch({ init: initialState });
  }, [initialState]);

  const CanvasState = useMemo(() => ({ state, dispatch }), [state]);

  return CanvasState;
};
