import { useRef, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { isDefined } from "@nextml/lodestar";

export const ResizeObserverWrapper = ({ children, onResize, ...props }) => {
  const wrapperRef = useRef(null);

  const resizeRef = useRef(
    new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;

      onResize({ resize: { width, height } });
    }),
  );

  useEffect(() => {
    const observerTarget = wrapperRef.current;
    const cleanupRef = resizeRef.current;

    if (isDefined(observerTarget)) {
      resizeRef.current.observe(observerTarget);
    }

    return () => {
      cleanupRef.unobserve(observerTarget);
    };
  }, []);

  return (
    <Box
      ref={wrapperRef}
      {...props}
    >
      {children}
    </Box>
  );
};
