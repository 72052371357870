import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { basePath } from "../utils/location";
import { redirectUriKey } from "./Login";

export const LogoutView = () => {
  const { keycloak } = useKeycloak();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const redirectUri = searchParams.has(redirectUriKey)
      ? decodeURIComponent(searchParams.get(redirectUriKey))
      : basePath();
    console.log(redirectUriKey, redirectUri);
    keycloak.logout({ redirectUri });
  }, [keycloak, searchParams]);
};
