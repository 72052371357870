import { useSearchParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { basePath } from "../utils/location";
import { useEffect } from "react";

export const redirectUriKey = "redirect_uri";

export const LoginView = () => {
  const { keycloak } = useKeycloak();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const redirectUri = searchParams.has(redirectUriKey)
      ? decodeURIComponent(searchParams.get(redirectUriKey))
      : basePath();
    console.log(redirectUriKey, redirectUri);
    keycloak.login({ redirectUri });
  }, [keycloak, searchParams]);
};
