import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page404 } from "./Page404.jsx";
import { LoginView } from "./Login.jsx";
import { LogoutView } from "./Logout.jsx";
import { AuthenticatedView } from "./View.jsx";
import { InspectionView } from "./Inspection/index.jsx";
import { NotImplementedView } from "./NotImplemented.jsx";
import { MeasurementsView } from "./Measurements/index.jsx";

export const Views = () => (
  <BrowserRouter>
    <Routes>
      <Route
        index
        element={
          <AuthenticatedView>
            <MeasurementsView.Component />
          </AuthenticatedView>
        }
      />

      <Route
        path="/settings"
        element={
          <AuthenticatedView>
            <NotImplementedView />
          </AuthenticatedView>
        }
      />

      <Route
        path="/measurements/:measurementId?/images/:imageId?"
        element={
          <AuthenticatedView>
            <InspectionView.Component />
          </AuthenticatedView>
        }
      />

      <Route
        path="/measurements"
        element={
          <AuthenticatedView>
            <MeasurementsView.Component />
          </AuthenticatedView>
        }
      />

      <Route
        exact
        path="/login"
        element={<LoginView />}
      />
      <Route
        exact
        path="/logout"
        element={<LogoutView />}
      />
      <Route
        path="*"
        element={<Page404 />}
      />
    </Routes>
  </BrowserRouter>
);
