import { isDefined } from "@nextml/lodestar";

export const match = (conditions) => (x) => {
  const [key] = Object.keys(x);

  if (!isDefined(key)) {
    throw Error(`unable to match ${x}, no keys found`);
  }

  if (isDefined(conditions[key])) {
    return conditions[key](x[key]);
  } else {
    throw Error(`missing match condition for key: ${key}`);
  }
};
