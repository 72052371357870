import { useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { ImageList } from "./ImageList/index.jsx";

import { ImageViewer } from "./ImageViewer.jsx";
import { Layout } from "../Layout/index.jsx";
import { If } from "@nextml/lodestar-react";
import { isDefined } from "@nextml/lodestar";

import { useEffect } from "react";
import { useApi } from "../../api/useApi.js";
import { useUrlNavigation } from "../../state/url/index.js";
import { match } from "../../utils/match.js";
import { ViewContextProvider, useViewContext } from "../ContextProvider.jsx";

const at = (measurementId, imageId) =>
  isDefined(imageId)
    ? `/measurements/${measurementId}/images/${imageId}`
    : `/measurements/${measurementId}/images`;

const useGoToFirstImageIfUndefined = (measurementId, imageId) => {
  const { Images } = useApi();
  const navigate = useUrlNavigation();
  const {
    state: { filter },
  } = useViewContext();

  // go to first image in measurement if no image is already selected
  useEffect(() => {
    if (!isDefined(imageId) && isDefined(measurementId, Images, navigate)) {
      Images.list({
        query: {
          measurementId,
          ...filter.selected,
        },
      }).then(
        match({
          ok: (imageList) => {
            navigate.to(InspectionView.at(measurementId, imageList[0].id));
          },
        }),
      );
    }
  }, [Images, navigate, measurementId, imageId, filter.selected]);
};

const Component = () => {
  const { measurementId, imageId } = useParams();

  useGoToFirstImageIfUndefined(measurementId, imageId);

  return (
    <Layout backgroundColor={"#000000"}>
      <Flex
        paddingLeft={7}
        paddingTop={1}
        paddingBottom={1}
        width={"100%"}
        justifyContent={"right"}
        zIndex={10000}
      >
        <ImageList.Filter />
      </Flex>
      <Flex
        width={"100%"}
        height={"100vh"}
        paddingBottom={10}
        paddingLeft={7}
        flex={1}
      >
        <Flex
          flexDirection={"column"}
          height="100%"
          flex="1"
          position="relative"
        >
          <If test={isDefined(imageId)}>
            <ImageViewer imageId={imageId} />
          </If>
        </Flex>
        <Flex
          direction="column"
          paddingLeft={3}
          paddingRight={3}
          width={"220px"}
        >
          <ImageList.Carousel
            measurementId={measurementId}
            imageId={imageId}
          />
        </Flex>
      </Flex>
    </Layout>
  );
};

const initialState = {
  filter: {
    options: [
      {
        value: { predictionLabel: "post.rust" },
        label: "Rusty post",
      },
      {
        value: { predictionLabel: "fastening.missing" },
        label: "Missing fastening",
      },
    ],
    selected: {
      predictionLabel: [],
    },
  },
};

const reducer = (state, action) =>
  match({
    setFilter: (selected) => ({
      ...state,
      filter: {
        ...state.filter,
        selected,
      },
    }),
  })(action);

export const InspectionView = {
  Component: () => (
    <ViewContextProvider
      reducer={reducer}
      initialState={initialState}
    >
      <Component />
    </ViewContextProvider>
  ),
  at,
};
