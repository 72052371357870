import { binaryToOverlay } from "./binaryToOverlay";
import { Image } from "../Image/index.js";

export const convertImageToOverlay = (image, color) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = image.width;
  canvas.height = image.height;
  ctx.drawImage(image, 0, 0);

  const binaryImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const overlayImageData = binaryToOverlay(binaryImageData, color);
  ctx.putImageData(overlayImageData, 0, 0);

  return Image.load(canvas.toDataURL("image/png"), {
    crossOrigin: "Anonymous",
  });
};
