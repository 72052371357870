import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "../hooks/useDebounce.js";

const Context = createContext();

export const useSearchParamState = () => useContext(Context);

const keyValuePairs = (result, [key, value]) => ({
  ...result,
  [key]: value,
});

const reduceSearchParams = (searchParams) =>
  [...searchParams.entries()].reduce(keyValuePairs, {});

const reduceConfig = (config, compare) =>
  Object.keys(config).reduce((result, key) => {
    return {
      ...result,
      [key]: compare[key] || config[key],
    };
  }, config);

const initialState = (config, searchParams) =>
  reduceConfig(config, reduceSearchParams(searchParams));

export const SearchParamStateProvider = ({ children, config }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(initialState(config, searchParams));
  const debounce = useDebounce();

  useEffect(() => {
    setState(initialState(config, searchParams));
  }, [config, searchParams]);

  useEffect(() => {
    debounce(() => setSearchParams(state), { timeout: 300 });
  }, [debounce, setSearchParams, state]);

  const Params = useMemo(
    () => ({
      state,
      set: (update) =>
        setState((previousState) => ({ ...previousState, ...update })),
    }),
    [state],
  );

  return <Context.Provider value={Params}>{children}</Context.Provider>;
};
