import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App.jsx";
import { loadConfig } from "./config/index.js";
import reportWebVitals from "./reportWebVitals";
import { AuthenticationProvider } from "./authentication/AuthenticationProvider.jsx";

loadConfig().then((config) => {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    // needs to be put outside <React.StrictMode>
    // https://github.com/react-keycloak/react-keycloak/issues/93#issuecomment-1158011748
    <AuthenticationProvider config={config}>
      <React.StrictMode>
        <App config={config} />
      </React.StrictMode>
    </AuthenticationProvider>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
