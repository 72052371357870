import {
  useCallback,
  cloneElement,
  useEffect,
  useState,
  Children,
} from "react";

import { If } from "@nextml/lodestar-react";

import { Table as ChakraTable, Tr, Flex, Thead } from "@chakra-ui/react";

import { rotateSortOrder } from "./utils/rotateSortOrder.js";
import { ComponentLoader } from "./ComponentLoader.jsx";

export const TableWrapper = ({ children, isLoading, ...props }) => (
  <Flex
    direction="column"
    backgroundColor="white"
    paddingLeft="5"
    paddingRight="5"
    paddingBottom="5"
    borderRadius="xl"
    position="relative"
    width="full"
    height="full"
    overflowY="scroll"
    className="hide-scrollbar"
    boxShadow="md"
    {...props}
  >
    <If test={isLoading}>
      <Flex
        width="full"
        height="full"
        backgroundColor="whiteAlpha.800"
        position="absolute"
        left="0"
        top="0"
        alignItems="center"
        justifyContent="center"
        zIndex="overlay"
      >
        <ComponentLoader>Loading table data...</ComponentLoader>
      </Flex>
    </If>
    <If test={!isLoading}>{children}</If>
  </Flex>
);

export const Table = ({ children }) => (
  <ChakraTable size="sm">{children}</ChakraTable>
);

export const TableHeader = ({ children, onChange }) => {
  const [state, setState] = useState({ sortBy: "", sortOrder: "descending" });

  useEffect(() => {
    onChange(state);
  }, [onChange, state]);

  const sortColumnBy = useCallback(
    (attribute) => () => {
      setState((state) => ({
        sortBy: attribute,
        sortOrder:
          attribute !== state.sortBy
            ? "descending"
            : rotateSortOrder(state.sortOrder),
      }));
    },
    [],
  );

  return (
    <Thead
      backgroundColor="white"
      position="sticky"
      top="0"
    >
      <Tr>
        {Children.map(children, (child) =>
          cloneElement(child, { onClick: sortColumnBy, ...state }),
        )}
      </Tr>
    </Thead>
  );
};
