import { useCallback, useEffect, useState } from "react";

import { Box } from "@chakra-ui/react";

import { useApi } from "../../../api/useApi.js";
import { InspectionView } from "../index.jsx";
import { match } from "../../../utils/match.js";
import { Carousel } from "../../../components/Carousel/index.jsx";
import { useUrlNavigation } from "../../../state/url/index.js";
import { useViewContext } from "../../ContextProvider.jsx";

const useImages = (measurementId) => {
  const { Images } = useApi();
  const [images, setImages] = useState([]);
  const {
    state: { filter },
  } = useViewContext();

  useEffect(() => {
    const predictionLabels = filter.selected.predictionLabel?.join(",");

    Images.list({
      query: {
        measurementId,
        predictionLabels,
        ...(filter.selected.predictionLabel?.includes("post.rust")
          ? {
              predictionMinimumArea: 100,
            }
          : {}),
      },
    }).then(match({ ok: setImages, error: console.error }));
  }, [Images, filter, filter.selected, measurementId]);

  return images;
};

export const ImageCarousel = ({ measurementId, imageId }) => {
  const navigate = useUrlNavigation();

  const images = useImages(measurementId);

  const onChange = useCallback(
    (image) => {
      navigate.to(InspectionView.at(measurementId, image.id), {
        debounce: 50,
      });
    },
    [measurementId, navigate],
  );

  return (
    <Box
      height="100%"
      overflowY="hidden"
      tabIndex={1}
      zIndex={1}
      width={"100%"}
    >
      <Carousel
        data={images}
        component={CarouselImage}
        onClick={console.log}
        onChange={onChange}
        id={imageId}
        size={7}
      />
    </Box>
  );
};

const CarouselImage = ({ isSelected, ...image }) => {
  const navigate = useUrlNavigation();

  return (
    <Box
      direction="column"
      color="white"
      key={image.id}
      onClick={() =>
        navigate.to(InspectionView.at(image.measurement_id, image.id))
      }
      justifyContent="center"
      alignItems="center"
      position="relative"
      border={isSelected ? "3px solid white" : "3px solid transparent"}
      borderRadius="4px"
    >
      <img
        src={image.quality("width256").url}
        alt=""
        style={{
          borderRadius: "4px",
          filter: isSelected ? "none" : "opacity(100%)",
        }}
      />
    </Box>
  );
};
