import { Map } from "@nextml/lodestar-react";
import { Tbody } from "@chakra-ui/react";

export const TableRows = ({
  templateRow,
  data,
  onDoubleClick,
  onShowMore = () => {},
  keyFrom,
  rowLimit = NaN,
  numberOfColumns,
} = {}) => {
  // const [shownRows, _setShownRows] = useState(rowLimit);

  return (
    <>
      <Tbody>
        <Map
          // data={!isNaN(rowLimit) ? data.slice(0, shownRows) : data}
          data={data.slice(0, rowLimit)}
          component={templateRow}
          onDoubleClick={onDoubleClick}
          keyFrom={keyFrom}
        />
      </Tbody>

      {/* <If test={!isNaN(rowLimit)}>
        <Tbody>
          <Tr>
            <Td
              colSpan={numberOfColumns}
              borderBottom="none"
            >
              <Button
                variant={"ghost"}
                width={"full"}
                borderRadius={"none"}
                backgroundColor={"gray.50"}
                onClick={compose(
                  () => onShowMore(shownRows, shownRows),
                  () => setShownRows((rowLimit) => rowLimit + shownRows),
                )}
              >
                Show more
              </Button>
            </Td>
          </Tr>
        </Tbody>
      </If> */}
    </>
  );
};
