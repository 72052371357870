import { isDefined } from "@nextml/lodestar";
import { useEffect } from "react";

export const useEventListener = (listener, ref, callback) => {
  useEffect(() => {
    const element = ref?.current;
    if (isDefined(element)) {
      element.addEventListener(listener, callback, { passive: true });

      return () => {
        element.removeEventListener(listener, callback, { passive: true });
      };
    }
  }, [ref, listener, callback]);
};

export const useScrollListener = (ref, callback) =>
  useEventListener("wheel", ref, callback);
