import { Flex, Text } from "@chakra-ui/react";
import { Spinner } from "./Spinner.jsx";

export const ComponentLoader = ({ children }) => (
  <Flex alignItems="center">
    <Text fontSize="3xl">
      <Spinner marginRight="3" /> {children}
    </Text>
  </Flex>
);
