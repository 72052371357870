import { isDefined } from "@nextml/lodestar";
import { Canvas } from "./index.js";

const PendingImage = (shape, loadingOpacity) =>
  Canvas.rectangle({
    ...shape,
    color: `#1A202C${loadingOpacity ? loadingOpacity : "FF"}`,
    border: { color: "transparent" },
  });

// NOTE: This could potentially be an
// animated canvas element.
const LoadingImage = (shape, loadingOpacity) =>
  Canvas.rectangle({
    ...shape,
    color: `#1A202C${loadingOpacity ? loadingOpacity : "FF"}`,
    border: { color: "transparent" },
  });

const ErrorImage = (error) => {
  console.error(error);
  throw Error(`Canvas.image.ErrorImage not implemented`);
};

const LoadedImage = (data, shape) => (context) => {
  const { x, y, width, height } = shape;
  return context.drawImage(
    data,
    x,
    y,
    width || data.width,
    height || data.height,
  );
};

export const image =
  ({ loadingOpacity, ...shape }) =>
  // TODO: find a replacement for loadingOpacity
  (cachedImage) => {
    if (cachedImage.pending === true) {
      return PendingImage(shape, loadingOpacity);
    } else if (cachedImage.inProgress === true) {
      return LoadingImage(shape, loadingOpacity);
    } else if (isDefined(cachedImage.error)) {
      return ErrorImage(cachedImage.error);
    } else if (cachedImage.done) {
      return LoadedImage(cachedImage.done, shape);
    } else {
      throw Error(`CanvasImage: Not implemented ${cachedImage}`);
    }
  };
