import { Icon } from "@chakra-ui/react";
import { faArrowsRotate } from "@fortawesome/sharp-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Spinner = (props) => (
  <Icon
    as={FontAwesomeIcon}
    icon={faArrowsRotate}
    className="rotate"
    {...props}
  />
);
