import { useCallback, useMemo } from "react";

import { useApplicationState } from "@nextml/lodestar-react";

import { removeEmptyKeys } from "../utils/removeKey.js";

import { useAuthorizedFetcher } from "./useAuthorizedFetcher.js";
import { Url } from "./Url/index.js";

import { Images } from "./Images";
import { Measurements } from "./Measurements";
import { Predictions } from "./Predictions/index.js";

export const useApi = () => {
  const { config } = useApplicationState();
  const authorizedFetcher = useAuthorizedFetcher();

  const constructApiUrl = useCallback(
    ({ endpoint, parameters, query }) => {
      return `${config.api.endpoint}${Url.Parameters.replaceWith(
        endpoint,
        parameters,
      )}?${Url.Query.serialize(
        // NOTE: Should null, undefined, "", be removed or throw?
        // What is the best place for query parameter validation?
        removeEmptyKeys(query),
      )}`;
    },
    [config.api.endpoint],
  );

  const configureEndpointFor = useCallback(
    (fetchMethod) =>
      (endpoint) =>
      ({ query = {}, parameters = {} } = {}) => {
        const url = constructApiUrl({ endpoint, parameters, query });
        return fetchMethod(url);
      },
    [constructApiUrl],
  );

  const fetchEndpoint = useMemo(
    () => ({
      get: configureEndpointFor(authorizedFetcher.get),
      post: configureEndpointFor(authorizedFetcher.post),
    }),
    [authorizedFetcher.get, authorizedFetcher.post, configureEndpointFor],
  );

  const Api = useMemo(
    () => ({
      Images: Images(fetchEndpoint, constructApiUrl),
      Measurements: Measurements(fetchEndpoint, constructApiUrl),
      Predictions: Predictions(fetchEndpoint),
    }),
    [fetchEndpoint, constructApiUrl],
  );

  return Api;
};
