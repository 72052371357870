import { Box, Flex } from "@chakra-ui/react";

import { Layout } from "../Layout/index.jsx";
import { useApi } from "../../api/useApi.js";
import { useState, useEffect, useCallback } from "react";
import { Table, TableWrapper } from "./Table/Table.jsx";
import { ColumnHeader } from "./Table/ColumnHeader.jsx";
import { TableRows } from "./Table/TableRows.jsx";
import { TableHeader } from "./Table/Table.jsx";
import { TableRow } from "./Table/TableRow.jsx";
import { TableData } from "./Table/TableData.jsx";
import { InspectionView } from "../Inspection/index.jsx";
import { useUrlNavigation } from "../../state/url/index.js";

const at = () => `/measurements`;

const PlanningRow = (props) => {
  const { measurement_name, created } = props;

  const [year, dayOfYear, hour, minute, second, nanosecond] = created;

  // Create a date object directly
  const date = new Date(
    year,
    0,
    dayOfYear,
    hour,
    minute,
    second,
    nanosecond / 1000000,
  );

  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours(),
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;

  return (
    <TableRow onDoubleClick={() => props.onDoubleClick({ measurement: props })}>
      <TableData>
        <Flex alignItems={"center"}>{measurement_name}</Flex>
      </TableData>
      <TableData>
        <Flex alignItems={"center"}>{formattedDate}</Flex>
      </TableData>
    </TableRow>
  );
};

const Component = () => {
  return (
    <Layout padding="10">
      <Flex
        width={"100%"}
        height={"100vh"}
      >
        <Box
          height="100%"
          flex="1"
          position="relative"
        >
          <MeasurementList />
        </Box>
      </Flex>
    </Layout>
  );
};

const MeasurementList = () => {
  const { Measurements } = useApi();
  const navigate = useUrlNavigation();

  const [measurements, setMeasurements] = useState(null);

  useEffect(() => {
    const fetchMeasurements = async () => {
      setMeasurements(await Measurements.list({}));
    };

    fetchMeasurements();
  }, [Measurements]);

  const onTableHeaderChange = ({ sortBy, sortOrder }) => {
    console.log({ sortBy, sortOrder });
  };

  const onDoubleClick = useCallback(
    ({ measurement }) => {
      navigate.to(InspectionView.at(measurement.id));
    },
    [navigate],
  );

  const isLoading = measurements === null;

  return (
    <Box
      height="100%"
      overflowY="hidden"
      marginLeft={5}
      // minWidth="320px"
      tabIndex={1}
      zIndex={1}
    >
      <Flex
        direction={"column"}
        width="full"
        height="full"
        boxShadow="lg"
        overflowY="scroll"
        className="hide-scrollbar"
      >
        <TableWrapper isLoading={isLoading}>
          <Table>
            <TableHeader onChange={onTableHeaderChange}>
              <ColumnHeader>Measurement name</ColumnHeader>
              <ColumnHeader>Created</ColumnHeader>
            </TableHeader>

            <TableRows
              data={measurements === null ? [] : measurements}
              templateRow={PlanningRow}
              onDoubleClick={onDoubleClick}
              // onShowMore={onShowMore}
              rowLimit={40}
              // TODO: Make the button full width
              // numberOfColumns should be the same as
              // in the header and row template
              numberOfColumns={2}
              keyFrom={(measurement) => [measurement.id]}
            />
          </Table>
        </TableWrapper>
      </Flex>
    </Box>
  );
};

export const MeasurementsView = {
  Component,
  at,
};
