import { Wrapper } from "./Wrapper.jsx";
import { FocusLine, FocusLineTag } from "./FocusLine.jsx";
import { BoundingBoxLayer } from "./BoundingBoxLayer.jsx";
import { MagnifyingGlass } from "./MagnifyingGlass.jsx";
import { ImageLayer } from "./ImageLayer.jsx";
import { SegmentationLayer } from "./SegmentationLayer.jsx";
import { ObjectInfo } from "./ObjectInfo.jsx";

export const InspectionCanvas = {
  Wrapper,
  FocusLine,
  FocusLineTag,
  BoundingBoxLayer,
  MagnifyingGlass,
  ImageLayer,
  SegmentationLayer,
  ObjectInfo,
};
