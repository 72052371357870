import { Box, Flex, Icon } from "@chakra-ui/react";

import { faSpinner } from "@fortawesome/sharp-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useImageCache } from "../../state/cache/useCache.js";
import { If } from "@nextml/lodestar-react";

import { RenderCanvas } from "./RenderCanvas";
import { useCanvasContext } from "./Wrapper.jsx";
import { isDefined } from "@nextml/lodestar";
import { useMemo } from "react";
import { Canvas } from "../../components/InspectionCanvas/Canvas/index.js";

const Loader = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="100%"
    position="absolute"
    top="0"
    left="0"
    zIndex={"1000"}
  >
    <Icon
      as={FontAwesomeIcon}
      fontSize={"8xl"}
      icon={faSpinner}
      className="rotate"
      color="whiteAlpha.700"
    />
  </Flex>
);

export const MagnifyingGlass = ({ imageId, scale = 9504 / 1920 / 1.1 }) => {
  const imageCache = useImageCache();
  const {
    state: { dimensions, mouse, magnifier, translation },
  } = useCanvasContext();

  const image = useMemo(
    () => ({
      render: Canvas.image({
        x: 0,
        y: 0,
        height: dimensions.original.height,
        width: dimensions.original.width,
      })(imageCache.poll(imageId, "full")),
    }),
    [imageCache, imageId, dimensions],
  );

  const quality = "full";
  const isLoading =
    (isDefined(imageCache.poll(imageId, quality).pending) ||
      isDefined(imageCache.poll(imageId, quality).inProgress)) &&
    !isDefined(imageCache.poll(imageId, quality)?.done) &&
    !isDefined(imageCache.poll(imageId, quality)?.error);

  const imageWidth = dimensions.original.width;
  const imageHeight = dimensions.original.height;

  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      boxSizing="border-box"
      zIndex="200"
      width={dimensions.ui.width}
      height={dimensions.ui.height}
      boxShadow="0 0 10px 5px #222222"
      className="no-background-background"
      display={magnifier.display ? "block" : "none"}
    >
      <If test={magnifier.display}>
        <RenderCanvas
          renderables={[image]}
          dimensions={{
            width: dimensions.ui.width,
            height: dimensions.ui.height,
          }}
          scale={scale}
          // NOTE:
          // Moving the mouse will change the translation of
          // the canvasState. This will cause RenderCanvas to update
          // the context state i.e. setContext on every mouse move
          // throwing the maximum depth exceeded error.
          // Keep <If ... to only show the magnifying canvas
          // when open. This reduces the number of state changes
          // triggered by the hook.
          //
          // TODO:
          // A solution might be to throttle the mouse move event
          // with requestAnimationFrame.
          translation={{
            x:
              translation.x -
              imageWidth * mouse.position.x + // absolute position of mouse on the image
              (dimensions.ui.width * mouse.position.x) / scale, // focus of image should be on mouse point
            y:
              translation.y -
              imageHeight * mouse.position.y + // absolute position of mouse on the image
              (dimensions.ui.height * mouse.position.y) / scale, // focus of image should be on mouse point
          }}
        />
      </If>

      <If test={isLoading}>
        <Loader />
      </If>
    </Box>
  );
};
