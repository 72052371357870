import { createContext, useCallback, useContext, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce.js";
import { useSearchParamState } from "../searchParamState.js";

const UrlStateContext = createContext();

export const useUrlNavigation = () => useContext(UrlStateContext);

export const UrlStateProvider = ({ children }) => {
  const debounce = useDebounce();
  const navigate = useNavigate();
  const Params = useSearchParamState();

  const to = useCallback(
    (url, { debounce: timeout = 0, preserveQueryParameters = false } = {}) =>
      debounce(
        () => {
          navigate({
            pathname: url,
            search: preserveQueryParameters
              ? createSearchParams(Params.state).toString()
              : "",
          });
        },
        { timeout },
      ),
    [Params, debounce, navigate],
  );

  const navigateModule = useMemo(() => {
    return { to };
  }, [to]);

  return (
    <UrlStateContext.Provider value={navigateModule}>
      {children}
    </UrlStateContext.Provider>
  );
};
