import { curry, isDefined } from "@nextml/lodestar";

const replaceWith = curry((endpoint, parameters) => {
  return endpoint
    .split("/")
    .map((part) => {
      if (part.charAt(0) === ":") {
        const [, parameterName] = part.split(":");
        if (isDefined(parameters[parameterName])) {
          return parameters[parameterName];
        } else {
          throw new Error(
            `Missing url parameter ${parameterName} for endpoint ${endpoint}`,
          );
        }
      }

      return part;
    })
    .join("/");
});

export const Parameters = {
  replaceWith,
};
