export const rotateSortOrder = (sortOrder) => {
  switch (sortOrder) {
    case "ascending":
      return "descending";
    case "descending":
      return "ascending";
    default:
      return "descending";
  }
};
