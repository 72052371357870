import { curry } from "@nextml/lodestar";

export const removeKey = curry((keyToRemove, x) =>
  Object.keys(x).reduce((result, key) => {
    if (key === keyToRemove) {
      return result;
    } else {
      return {
        ...result,
        [key]: x[key],
      };
    }
  }, {}),
);

export const removeEmptyKeys = (object) =>
  Object.keys(object).reduce(
    (newObject, key) =>
      [null, undefined, ""].includes(object[key])
        ? newObject
        : { ...newObject, [key]: object[key] },
    {},
  );
