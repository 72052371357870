import { rectangle } from "./rectangle.js";
import { image } from "./image.js";
import { polygon } from "./polygon.js";
import { boundingBox } from "./boundingBox.js";

export const Canvas = {
  rectangle,
  image,
  polygon,
  boundingBox,
};
