import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import theme from "./theme.js";
import AutoToggleColorMode from "./AutoToggleColorMode";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/300-italic.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/500-italic.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/700-italic.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/800-italic.css";

export const Styles = ({ children }) => {
  return (
    <ChakraProvider
      resetCSS
      theme={theme}
    >
      <AutoToggleColorMode />
      {children}
    </ChakraProvider>
  );
};
