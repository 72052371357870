// [{ foo: value1 }, { foo: value2 }, { bar: value1 }]
// => { foo: [value1, value2], bar: [value1] }
export const groupBy = (xs) =>
  xs.reduce((acc, x) => {
    const [key, value] = Object.entries(x)[0];

    return {
      ...acc,
      [key]: acc[key] ? [...acc[key], value] : [value],
    };
  }, []);
