export const boundingBox =
  ({ x, y, width, height, color, filled, border }) =>
  (context) => {
    if (filled) {
      context.fillStyle = color;
      context.beginPath();
      context.fillRect(x, y, width, height);
      context.stroke();
    } else {
      context.lineWidth = border;
      context.strokeStyle = color;
      context.beginPath();
      context.rect(x, y, width, height);
      context.stroke();
    }
  };
