import { RenderCanvas } from "./RenderCanvas.jsx";
import { Canvas } from "./Canvas/index.js";
import { useCanvasContext } from "./Wrapper.jsx";
import { useApi } from "../../api/useApi.js";
import { useMemo, useState, useEffect } from "react";

export const BoundingBoxLayer = ({ imageId }) => {
  const { Predictions } = useApi();
  const {
    state: { translation, scale, dimensions },
  } = useCanvasContext();
  const [resolvedPredictions, setResolvedPredictions] = useState([]);

  useEffect(() => {
    const predictions = Predictions.list({
      query: {
        type: "bounding_box",
        imageId: imageId,
      },
    });
    predictions
      .then((x) => {
        setResolvedPredictions(x);
      })
      .catch((error) => {
        console.error("Failed to resolve predictions:", error);
      });
  }, [Predictions, imageId]);

  const renderables = useMemo(() => {
    const bounding_box = (prediction) => {
      const data = prediction.data;
      const rescale = {
        width: dimensions.original.width / data.image_width,
        height: dimensions.original.height / data.image_height,
      };
      let color =
        prediction.label.split(".")[1] === "missing" ? "#FF0000" : "#00FF00";

      return {
        x: data.from_width * rescale.width,
        y: data.from_line * rescale.height,
        width: (data.to_width - data.from_width) * rescale.width,
        height: (data.to_line - data.from_line) * rescale.height,
        color: color,
        filled: false,
        border: 2,
      };
    };

    return resolvedPredictions
      .map((prediction) => bounding_box(prediction))
      .map((bbox) => ({
        render: Canvas.boundingBox(bbox),
      }));
  }, [dimensions.original, resolvedPredictions]);
  return (
    <RenderCanvas
      translation={translation}
      scale={scale}
      dimensions={dimensions.ui}
      renderables={renderables}
    />
  );
};
