import { Box, Flex, Heading, Image } from "@chakra-ui/react";

export const LoadingView = ({ message }) => {
  return (
    <Flex
      direction={"column"}
      alignItems="center"
      justifyContent={"center"}
      height="80vh"
    >
      <Box marginBottom={"10"}>
        <Image src="/logo.png" />
      </Box>
      <Heading
        textAlign={"center"}
        marginBottom="20"
        fontWeight={"normal"}
      >
        DeepInspection Track
      </Heading>
      <Heading size={"md"}>{message}</Heading>
    </Flex>
  );
};
