import { useCallback, useRef } from "react";
import { useScrollListener } from "../../hooks/useEventListener";
import { useCanvasContext } from "./Wrapper.jsx";

export const EventCaptureCanvas = () => {
  const {
    dispatch,
    state: { dimensions, mouse },
  } = useCanvasContext();
  const ref = useRef();

  const eventHandler = useCallback(
    (event) => {
      dispatch({ [event.type]: event });
    },
    [dispatch],
  );

  const onScroll = useCallback(
    (event) => {
      dispatch({ scroll: event });
    },
    [dispatch],
  );

  useScrollListener(ref, onScroll);

  return (
    <canvas
      tabIndex={-1}
      ref={ref}
      {...dimensions.ui}
      onMouseMove={eventHandler}
      onMouseDown={eventHandler}
      onMouseUp={eventHandler}
      onKeyDown={eventHandler}
      onMouseLeave={eventHandler}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1000,
        cursor: mouse.cursor,
      }}
    />
  );
};
