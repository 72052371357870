import { compose, noop } from "@nextml/lodestar";
import { useCallback, useEffect, useMemo, useState } from "react";

const lastIndexOf = (xs) => xs.length - 1;

export const useCarousel = (xs, id, size = 5) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const locatedIndex = xs.findIndex((x) => x.id === id);
    if (locatedIndex >= 0) {
      setIndex(locatedIndex);
    } else {
      setIndex(0);
    }
  }, [id, xs]);

  const decrement = useCallback(() => {
    setIndex(
      compose(
        (index) => index - 1,
        (index) => (index < 0 ? 0 : index),
      ),
    );
  }, []);

  const increment = useCallback(() => {
    setIndex(
      compose(
        (index) => index + 1,
        (index) => (index > lastIndexOf(xs) ? lastIndexOf(xs) : index),
      ),
    );
  }, [xs]);

  const carouselData = useMemo(() => {
    return {
      top: xs.slice(index - size < 0 ? 0 : index - size, index),
      focusedImage: xs[index],
      bottom: xs.slice(index + 1, index + size + 1),

      onKeyDown: (event) => {
        switch (event.key) {
          case "ArrowUp": {
            // Reverse order to add a feeling of
            // naturally moving forward and backward
            return increment();
          }

          case "ArrowDown": {
            // Reverse order to add a feeling of
            // naturally moving forward and backward
            return decrement();
          }

          default: {
            return;
          }
        }
      },

      onScroll: noop,
    };
  }, [decrement, increment, index, size, xs]);

  return carouselData;
};
