export const rectangle =
  ({ x, y, width, height, color, border }) =>
  (context) => {
    context.beginPath();
    context.lineWidth = border?.width || 0;
    context.strokeStyle = border?.color || "transparent";

    context.rect(x, y, width, height);
    context.fillStyle = color || "transparent";
    context.fillRect(x, y, width, height);

    context.stroke();
  };
