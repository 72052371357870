export const polygon =
  ({ coordinates, color, border }) =>
  (context) => {
    const [startCoordinate, ...restCoordinates] = coordinates;
    context.beginPath();

    context.fillStyle = color || "transparent"; // eslint-disable-line no-param-reassign
    context.strokeStyle = border?.color || "transparent"; // eslint-disable-line no-param-reassign, max-len
    context.lineWidth = border?.width || 0;

    context.moveTo(startCoordinate.x, startCoordinate.y);

    restCoordinates.forEach(({ x, y }) => context.lineTo(x, y));

    context.closePath();
    context.fill();
    context.stroke();
  };
