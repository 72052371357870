import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link, Icon } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NavLink = ({ name, faIcon, link }) => {
  const { pathname } = useLocation();
  const viewPath = `/${pathname.split("/")[1]}`;
  const isSelected = viewPath === link;

  return (
    <Link
      as={RouterLink}
      to={link}
      width="full"
      color={isSelected ? "whiteAlpha.900" : "whiteAlpha.500"}
      fontWeight="bold"
      textTransform="uppercase"
      fontSize="12px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={"center"}
      textAlign="center"
      _focus={{ outline: "none" }}
      _hover={{ color: "white" }}
    >
      <Icon
        as={FontAwesomeIcon}
        icon={faIcon}
        fontSize="28px"
        marginBottom="2"
      />

      {name}
    </Link>
  );
};
